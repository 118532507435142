
  import {defineComponent} from 'vue';
  import User from '@/model/User';
  import UserPortalInput from "@/components/common/input/UserPortalInput.vue";
  import UserPortalButton from "@/components/common/button/UserPortalButton.vue";
  import GroupManagement from "@/components/groups/GroupManagement.vue";
  import router from "@/router";
  import SnackbarType from "@/constants/SnackbarType";
  import LoggerUtils from "@/utils/LoggerUtils";
  import UserWithGroups from '@/model/UserWithGroups';

  export default defineComponent({
    name: 'UserForm',
    components: {
      UserPortalInput,
      UserPortalButton,
      GroupManagement
    },
    props: {
      edit: {
        required: false as false,
        type: Boolean,
        default: false
      },
      user: {
        required: false as false,
        type: User,
        default: User.empty()
      },
      displayGroups: {
        required: false as false,
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        originalUser: JSON.parse(JSON.stringify(this.user)) as User,
        currentUser: this.user == null ? User.empty() : this.user,
        selectedGroups: null as Array<string> | null,
        originalSelectedGroups: null as Array<string> | null,
        isUserChanged: false as boolean,
      }
    },
    watch: {
      currentUser: {
        handler(value: User) {
          const hasInvalidFields: boolean = this.hasAnyInvalidFields;
          this.$emit("has-invalid-fields", hasInvalidFields);

          this.isUserChanged = (value.firstName || "") !== (this.originalUser.firstName || "") ||
            (value.lastName || "") !== (this.originalUser.lastName || "") ||
            (value.username || "") !== (this.originalUser.username || "") ||
            (value.mail || "") !== (this.originalUser.mail || "");
          this.$emit("is-user-changed", this.isUserChanged);
        },
        deep: true,
        immediate: true
      },
      selectedGroups: {
        handler(value: Array<string>) {
          if (this.originalSelectedGroups) {
            this.isUserChanged = value.length !== this.originalSelectedGroups.length ||
              value.some(g => !(this.originalSelectedGroups || []).includes(g));
            this.$emit("is-user-changed", this.isUserChanged);
          }
        },
        deep: true,
        immediate: true
      }
    },
    computed: {
      hasAnyInvalidFields(): boolean {
        return !this.isValid(this.currentUser.firstName) || !this.isValid(this.currentUser.lastName) ||
          !this.isValidUsername(this.currentUser.username) || (!this.edit && !this.isValid(this.currentUser.password)) ||
          !this.isValidEmail(this.currentUser.mail);
      },
      getValidationMessageForEmail(): string {
        if(this.isEmailNullOrEmpty(this.currentUser.mail)) {
          return '';
        }
        return this.isValidEmail(this.currentUser.mail) ? '' : 'Invalid E-mail format!';
      },
      getValidationMessageForUsername(): string {
        if (this.isValidUsername(this.currentUser.username)) {
          return '';
        }
        return this.currentUser.username.length > 0 ?
          "Only lower case letters, special characters and numbers are allowed for Username!" : "";
      },
    },
    methods: {
      isValid(value: string): boolean {
        return value ? value.length > 0 : false;
      },
      isValidEmail(email: string): boolean {
        const emailValidationRegex = new RegExp(
          '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
        );
        return this.isEmailNullOrEmpty(email) ? false : emailValidationRegex.test(email);
      },
      isEmailNullOrEmpty(email: string | null) {
        return email === null || email === '';
      },
      isValidUsername(username: string | null): boolean {
        const usernameValidationRegex = new RegExp('^[^A-Z]+$');
        return username ? username.length > 0 && usernameValidationRegex.test(username) : false;
      },
      onSelectedGroupsChanged(selectedGroups: Array<string>) {
        if (!this.selectedGroups) {
          this.originalSelectedGroups = JSON.parse(JSON.stringify(selectedGroups));
        }
        this.selectedGroups = selectedGroups;
      },
      async saveUser(): Promise<void> {
        if (this.edit) {
          this.updateUser();
        } else {
          this.createUser();
        }
      },
      async createUser(): Promise<void> {
        const userWithGroups: UserWithGroups = new UserWithGroups(this.currentUser, this.selectedGroups || []);
        try {
          await this.$store.direct.dispatch.userModule.createUser(userWithGroups);
          await this.onSuccess('User created successfully!');
        } catch (e) {
          const errorMessage: string = "Error while creating new user. " + await e.response.text();
          await this.$store.direct.dispatch.snackbarModule.displaySnackbar({
            message: errorMessage,
            type: SnackbarType.ERROR
          });
          LoggerUtils.error(errorMessage);
        }
      },
      async updateUser(): Promise<void> {
        const userWithGroups: UserWithGroups = new UserWithGroups(this.currentUser, this.selectedGroups || []);
        try {
          await this.$store.direct.dispatch.userModule.updateUser(userWithGroups);
          await this.onSuccess('User updated successfully!');
          this.originalUser = JSON.parse(JSON.stringify(this.currentUser));
          this.originalSelectedGroups = JSON.parse(JSON.stringify(this.selectedGroups));
          this.isUserChanged = false;
        } catch (e) {
          const errorMessage: string = "Error while updating user. " + await e.response.text();
          await this.onError(errorMessage, e);
        }
      },
      async onSuccess(message: string): Promise<void> {
        if (!this.edit) {
          await router.push('accessManagement');
        }
        await this.$store.direct.dispatch.snackbarModule.displaySnackbar({
          message: message,
          type: SnackbarType.SUCCESS
        });
      },
      async onError(message: string, error: Error): Promise<void> {
        await this.$store.direct.dispatch.snackbarModule.displaySnackbar({
          message: message,
          type: SnackbarType.ERROR
        });
        await router.push('accessManagement');
        LoggerUtils.error(`${message} Reason: ${error.message}`);
      }
    }
  });
